import { Routes } from "@angular/router";
import { AuthGuard } from "@libs/guards/auth/auth.guard";
import { confirmDeactivateGuard } from "./guards/confirm-deactivate.guard";

export const routes: Routes = [
  {
    path: "",
    canActivate: [AuthGuard],
    loadComponent: () => import("./pages/home/home.component").then((m) => m.HomeComponent),
    canDeactivate: [confirmDeactivateGuard],
  },
  {
    path: "home",
    canActivate: [AuthGuard],
    loadComponent: () => import("./pages/home/home.component").then((m) => m.HomeComponent),
    canDeactivate: [confirmDeactivateGuard],
  },
  {
    path: "auction",
    canActivate: [AuthGuard],
    loadComponent: () => import("./pages/auction-panel/auction-panel.component").then((m) => m.AuctionPanelComponent),
  },
  {
    path: "notifications",
    canActivate: [AuthGuard],
    loadComponent: () => import("./pages/notifications/notifications.component").then((m) => m.NotificationsComponent),
  },
  {
    path: "faq",
    canActivate: [AuthGuard],
    loadComponent: () => import("./pages/faq/faq.component").then((m) => m.FaqComponent),
  },
  {
    path: "activies",
    canActivate: [AuthGuard],
    loadComponent: () => import("./pages/activies/activities.component").then((m) => m.ActivitiesComponent),
  },
  {
    path: "find-agency-message",
    loadComponent: () => import("./pages/find-agency-message/find-agency-message.component").then((m) => m.FindAgencyMessageComponent),
  },
  {
    path: "start",
    loadComponent: () => import("./pages/start/start.component").then((m) => m.StartComponent),
  },
  {
    path: "login",
    loadComponent: () => import("./pages/login/login.component").then((m) => m.LoginComponent),
  },
  {
    path: "register",
    loadComponent: () => import("./pages/register/register.component").then((m) => m.RegisterComponent),
  },
  {
    path: "confirm-code",
    loadComponent: () => import("./pages/confirm-code/confirm-code.component").then((m) => m.ConfirmCodeComponent),
  },
  {
    path: "create-password",
    loadComponent: () => import("./pages/create-password/create-password.component").then((m) => m.CreatePasswordComponent),
  },
  {
    path: "forgot-password",
    loadComponent: () => import("./pages/forgot-password/forgot-password.component").then((m) => m.ForgotPasswordComponent),
  },
  {
    path: "**",
    redirectTo: "",
    pathMatch: "full",
  },
];
