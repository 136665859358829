import { differenceInSeconds, fromUnixTime } from "date-fns";
import moment from "moment";
import { google } from "../proto/google/protobuf/timestamp";

export const getDurationFormatted = (createdAt, finishedAt) => {
  if (!createdAt || !finishedAt) {
    return "N/A";
  }
  const startDate = fromUnixTime(createdAt.seconds);
  const endDate = fromUnixTime(finishedAt.seconds);
  const diffInSeconds = differenceInSeconds(endDate, startDate);
  let duration = "";
  if (diffInSeconds >= 3600) {
    const hours = Math.floor(diffInSeconds / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = diffInSeconds % 60;
    duration = `${hours}h ${minutes}m ${seconds}s`;
  } else if (diffInSeconds >= 60) {
    const minutes = Math.floor(diffInSeconds / 60);
    const seconds = diffInSeconds % 60;
    duration = `${minutes}m ${seconds}s`;
  } else {
    duration = `${diffInSeconds}s`;
  }

  return duration;
};

export const convertToTimestamp = (dateString: string): google.protobuf.Timestamp => {
  const date = moment(dateString);
  return google.protobuf.Timestamp.fromObject({
    seconds: date.unix(),
    nanos: date.millisecond() * 1000000,
  });
};

export const momentToTimestamp = (date: moment.Moment): google.protobuf.Timestamp => {
  return google.protobuf.Timestamp.fromObject({
    seconds: date.unix(),
    nanos: date.millisecond() * 1000000,
  });
};

export const convertFromTimestamp = (timestamp: google.protobuf.Timestamp): Date => {
  return new Date(timestamp.seconds * 1000);
};
