import { Auction } from "@libs/models/auction";
import { Bid } from "@libs/models/bid";
import { convertFromTimestamp } from "@libs/util/date";
import { Injectable } from "@angular/core";
import { of, ReplaySubject } from "rxjs";
import { SharedService } from "@libs/services/shared/shared.service";

export const PERCENTAGE_DECIMAL_PLACES = 4;
export const CURRENCY_DECIMAL_PLACES = 2;
export const PERCENTAGE_DIVISOR = Math.pow(10, PERCENTAGE_DECIMAL_PLACES);
export const CURRENCY_DIVISOR = Math.pow(10, CURRENCY_DECIMAL_PLACES);

export const currentAuction$ = new ReplaySubject<Auction>(1);

@Injectable({
  providedIn: "root",
})
export class DataService {
  private _selectedAuctionsIds: string[];

  constructor(public shared: SharedService) {}

  loadDataWithUser() {
    return of(null);
  }

  resetData() {}

  get selectedAuctionsIds(): string[] {
    if (this._selectedAuctionsIds) {
      return this._selectedAuctionsIds;
    } else {
      return JSON.parse(localStorage.getItem("selectedAuctionsIds") ?? "[]");
    }
  }
  set selectedAuctionsIds(auctionsIds: string[]) {
    this._selectedAuctionsIds = auctionsIds;
    localStorage.setItem("selectedAuctionsIds", JSON.stringify(auctionsIds));
  }

  auctionDto(auction: Auction): Auction {
    const createdAt: Date = new Date(auction.metaData.data_de_inicio_do_leilao);
    const finishedAt: Date = new Date(auction.metaData.data_de_encerramento_do_leilao);
    return {
      ...auction,
      data: {
        seconds: Number(auction.metaData?.segundos_para_encerramento), // Segundos para encerramento
        bids: auction.bid.map((bid) => this.bidDto(bid)), // Lances
        createdAt: createdAt, // Data de início do leilão
        finishedAt: finishedAt, // Data de encerramento do leilão
        maximumDiscount: Number(auction.metaData.desagio_maximo) / PERCENTAGE_DIVISOR, // Porcentagem de deságio máximo
        commonFundPaid: Number(auction.metaData.fundo_comum_pago) / PERCENTAGE_DIVISOR, // Porcentagem de fundo comum pago
        bestBid: auction.metaData.melhor_lance ? Number(auction.metaData.melhor_lance) / PERCENTAGE_DIVISOR : null, // Porcentagem de melhor lance
        myBid:
          auction.lastBidUser && auction.lastBidUser[this.shared.user.id]
            ? auction.lastBidUser[this.shared.user.id].bidValue / PERCENTAGE_DIVISOR
            : null, // Meu lance
        isBest: auction.lastBidUser && auction.lastBidUser[this.shared.user.id] ? auction.lastBidUser[this.shared.user.id].isBest : false, // Meu lance é o melhor
        quotaValue: Number(auction.metaData.valor_da_cota) / CURRENCY_DIVISOR, // Valor da cota
        category: auction.metaData.categoria, // Categoria
        group: auction.metaData.grupo, // Grupo
        isFinished: auction.metaData.is_finished === "true" ? true : false, // Leilão finalizado
        lotName: auction.metaData.nome_do_lote, // Nome do lote
        debtBalance: Number(auction.metaData.saldo_devedor) / CURRENCY_DIVISOR, // Saldo devedor
        administrativeFee: Number(auction.metaData.taxa_administrativa) / PERCENTAGE_DIVISOR, // Taxa administrativa
        totalSeconds: Math.floor((finishedAt.getTime() - createdAt.getTime()) / 1000), // Total de segundos do leilão inteiro
      },
    };
  }

  bidDto(bid: Bid): Bid {
    return {
      ...bid,
      data: {
        userId: bid.userSub, // ID do usuário
        createdAt: convertFromTimestamp(bid.createdAt), // Data de criação
        bidValue: Number(bid.bidValue) / PERCENTAGE_DIVISOR, // Valor do lance
        description: this.shared.user.id === bid.userSub ? `deu um lance por: ${bid.managementName}` : `${bid.userNick} deu um lance.`,
      },
    };
  }
}
