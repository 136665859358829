import { CanDeactivateFn } from "@angular/router";
import { HomeComponent } from "../pages/home/home.component";

export const confirmDeactivateGuard: CanDeactivateFn<HomeComponent> = (component: HomeComponent, currentRoute, currentState, nextState) => {
  // If blockBack is false, allow navigation
  if (!component.blockBack) {
    return true;
  }

  // If nextState is not defined, allow navigation
  if (!nextState) {
    return true;
  }

  // Check if the destination URL is '/login' or starts with '/login'
  const destinationUrl = nextState.url;

  if (destinationUrl === "/login" || destinationUrl.startsWith("/login")) {
    // blockBack is true and destination is '/login', block navigation
    return !component.blockBack;
  } else {
    // Destination is not '/login', allow navigation
    return true;
  }
};
