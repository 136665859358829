import localePt from "@angular/common/locales/pt";
import { APP_NAME, LOAD_DATA_WITH_USER, RESET_DATA } from "@libs/main.service";
import { ApplicationConfig, importProvidersFrom, LOCALE_ID } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DataService } from "@services/data.service";
import { DateAdapter, MAT_DATE_LOCALE, NativeDateAdapter } from "@angular/material/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { provideEnvironmentNgxMask } from "ngx-mask";
import { provideHttpClient } from "@angular/common/http";
import { provideRouter } from "@angular/router";
import { registerLocaleData } from "@angular/common";
import { routes } from "./app.routes";

registerLocaleData(localePt);

export class CustomDateAdapter extends NativeDateAdapter {
  getFirstDayOfWeek(): number {
    return 0; // Domingo
  }

  getDayOfWeekNames(style: "long" | "short" | "narrow"): string[] {
    if (style === "narrow") {
      return ["D", "S", "T", "Q", "Q", "S", "S"];
    }

    if (style === "short") {
      return ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"];
    }

    return ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
  }

  getMonthNames(style: "long" | "short" | "narrow"): string[] {
    if (style === "narrow") {
      return ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"];
    }

    if (style === "short") {
      return ["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"];
    }

    return ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
  }
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(),
    provideRouter(routes),
    provideEnvironmentNgxMask(),
    { provide: LOCALE_ID, useValue: "pt-BR" },
    { provide: MAT_DATE_LOCALE, useValue: "pt-BR" },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    importProvidersFrom([BrowserAnimationsModule]),
    MatSnackBar,
    provideAnimationsAsync(),
    {
      provide: APP_NAME,
      useValue: "ecotas",
    },
    {
      provide: LOAD_DATA_WITH_USER,
      useFactory: (dataService: DataService) => dataService.loadDataWithUser.bind(dataService),
      deps: [DataService],
    },
    {
      provide: RESET_DATA,
      useFactory: (dataService: DataService) => dataService.resetData.bind(dataService),
      deps: [DataService],
    },
  ],
};
